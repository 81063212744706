<template>
  <CreateTenderDialog
    v-if="store.myTendersFetched"
    :tender="tender"
    :tender-stub="tenderStub"
    :create-button-disabled="isCreateTenderButtonDisabled()"
    :createAsTemplate="createAsTemplate"
  >
    <template #create-tender-wizard-step>
      <CreateTenderWizardStep :createAsTemplate="createAsTemplate"></CreateTenderWizardStep>
    </template>
  </CreateTenderDialog>
</template>

<script setup lang="ts">
import { Tender, useStore } from '@dims/components';
import CreateTenderWizardStep from '@/components/create-tender/CreateTenderWizardStep.vue';
import { useStore0214 } from '@/store/store0214';
import { tenderStub as tenderStubDefault } from '@/services';

const { tender = null, createAsTemplate = false } = defineProps<{
  /** Only when the create/clone button is on an existing tender */
  tender?: Tender | null,
  createAsTemplate?: boolean,
}>();
const store = useStore();
const store0214 = useStore0214();

function isCreateTenderButtonDisabled() {
  return !store0214.draftTender?.description;
}

const tenderStub = { ...tenderStubDefault, agreementName: store.agreementId };

</script>
