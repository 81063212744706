<template>
  <div>
    <LoadingSpinner :visible="isLoading" loadingMessage="Henter tilbud" />
    <div v-if="!isLoading && questionnaire">
      <v-card v-if="tender" class="pa-3" color="#fff9ef">
        <v-card-text>
          <div class="pdf-button">
            <v-btn
              variant="text"
              @click="downloadOffer()"
              class="float-right"
            >Download tilbud<v-icon end>mdi-download</v-icon></v-btn>
            <LoadingSpinner
              :visible="isLoading"
              loadingMessage="Danner PDF..."
            />
          </div>
          <div>{{ tender.user.companyName }}</div>
          <div>{{ tender.description }}</div>
          <div>{{ tender.friendlyId }}</div>
          <h1 class="font-weight-bold">Tilbud</h1>
          <h3 class="mt-2">
            <p
              v-if="offer.sender.companyName && offerCvr"
              class="mb-1"
            >
              {{
                `${offer.sender.companyName}, CVR. ${offerCvr}`
              }}
            </p>
            <p
              v-if="offerPublishedDate && offer.sender.fullName"
              class="mb-1"
            >
              {{
                `Tilbud indsendt d. ${offerPublishedDate} af ${offer.sender.fullName}`
              }}
            </p>
            <br />
            <p class="mb-1">
              Tilbuddet opfylder samtlige krav i kravspecifikationen:
            </p>
            <p>
              <span v-if="offer.specificationAccepted">Ja, alle krav er opfyldt</span>
              <span
                class="text-error"
                v-else
              >Nej, alle krav er
                <span class="font-weight-bold">ikke</span> opfyldt</span>
            </p>
          </h3>
          <TcoCalculationSummary
            :questions="tcoCalculationQuestions"
            :tcoCalculationResult="tcoCalculationResult"
            cardStyle="#fff9ef"
            tableStyle="transparent"
          />
          <br /><br />
          <v-card-text>
            <div
              v-for="tab in supplierQuestions"
              :key="tab.questionId"
            >
              <h2>{{ tab.title }}</h2>
              <br />
              <ReadOnlyTemplateQuestion
                :section="tab"
                :question="tab"
                :questionnaire="questionnaire"
                :level="1"
                :panelState="panelState"
                @panelsUpdate="panelsUpdate"
                :tender="tender"
                :expandableRepeater="false"
              />
            </div>
          </v-card-text>
        </v-card-text>
      </v-card>
    </div>
    <LoadingSpinner
      :loadingMessage="loadingMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import {
  LoadingSpinner,
  Offer,
  Tender,
  dateUtils,
  useStore,
  DeepQuestionnaire,
  TemplateQuestionPanelState,
  ReadOnlyTemplateQuestion,
  DeepQuestion,
  QuestionTypesEnum,
  downloadFrom,
  useBackend,
} from '@dims/components';
import TcoCalculationSummary from '../OfferDetails/TcoCalculation/TcoCalculationSummary.vue';
import { setSupplierPriceTabs } from '@/services/questionnaireTabConfig';
import excludedQuestions from '@/services/offerSummaryConfig';

const { tender, offer } = defineProps<{ tender: Tender, offer: Offer }>();
const store = useStore();
const backend = useBackend();
const offerCvr = ref('');

const questionnaire = ref<DeepQuestionnaire | null>(null);

const isLoading = ref(false);
const loadingMessage = ref('Vent venligst');
const panelState = ref<TemplateQuestionPanelState>(TemplateQuestionPanelState.INIT);
const priceSections = ref<number[]>([]);

onMounted(async () => {
  await fetchQuestionnaire();
  offerCvr.value = await backend.supplierService.fetchCvrFromOrg(tender.agreementName, offer.sender.organizationNumber) ?? '';
});

async function fetchQuestionnaire() {
  isLoading.value = true;
  try {
    const deepQuestionnaire = await store.backend.deepQuestionnaireService
      .getReadonlyQuestionnaire(
        tender.id,
        'offer',
        offer.id,
      );
    questionnaire.value = deepQuestionnaire;
  } catch (e) {
    console.error(e);
    store.setSnackbarText('Error');
  }
  priceSections.value = setSupplierPriceTabs(
    questionnaire.value,
  );
  priceSections.value = priceSections.value.filter((section) => section !== -1);
  console.log('X', priceSections.value);
  isLoading.value = false;
}

// Find suppplier filled questions
function* getSuppplierQuestions(questions: DeepQuestion[]): IterableIterator<DeepQuestion> {
  for (const question of questions) {
    if (question.accessForSupplier === 'ReadWrite') {
      if (question.questions) {
        const mutableQuestion = { ...question };
        mutableQuestion.questions = [...getSuppplierQuestions(question.questions)];

        yield mutableQuestion;
      } else {
        yield question;
      }
    } else if (question.questions && question.accessForSupplier === 'ReadOnly' && hasReadWriteQuestions(question)) {
      const mutableQuestion = { ...question };
      mutableQuestion.questions = [...getSuppplierQuestions(question.questions)];

      yield mutableQuestion;
    } else if (question.questions && question.questionType !== QuestionTypesEnum.REPEATER) {
      yield* getSuppplierQuestions(question.questions);
    }
  }
}

function hasReadWriteQuestions(parentQuestion: DeepQuestion) {
  if (!parentQuestion.questions) {
    return false;
  }

  for (const question of parentQuestion.questions) {
    if (question.accessForSupplier === 'ReadWrite') {
      return true;
    }

    if (question.questions) {
      if (hasReadWriteQuestions(question)) {
        return true;
      }
    }
  }

  return false;
}

const supplierQuestions = computed((): DeepQuestion[] => Array.from(getSuppplierQuestions(questionnaire.value?.questions ?? [])));

async function downloadOffer() {
  if (tender.id) {
    isLoading.value = true;
    try {
      const archiveUrl = await store.backend.exportDataService.exportOffer(
        offer.id,
      );
      downloadFrom(archiveUrl);
    } catch (e) {
      console.error(e);
      store.setSnackbarText('Kan ikke downloade materiale.');
    } finally {
      isLoading.value = false;
    }
  }
}

const offerPublishedDate = computed((): string => {
  const date = offer.publishedDate;
  return dateUtils.shortDateTimeFormat(date);
});

function panelsUpdate() {
  panelState.value = TemplateQuestionPanelState.CHANGED;
}

// Questions used for offer status overview
const tcoCalculationQuestions = computed(
  () => questionnaire.value?.questions.filter((x, idx) => priceSections.value.includes(idx) && x.questions).flatMap((x) => x.questions ?? [])
    .filter((q) => !excludedQuestions.includes(q.questionId)) ?? [],
);

const tcoCalculationResult = computed(() => questionnaire.value?.tcoCalculationResult);

</script>
