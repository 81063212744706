/** Specifies IDs from the delivery agreement
 * Question IDs are named 'id' + a descriptive name for the question
 * Answer IDs are named 'id' + a descriptive name for the question
 * + 'Answer' + a descriptive name for the answer
 * Some IDs can be accessed through corresponding enums
*/
const deliveryAgreementIds = {
  idDeliveryDate: '8b238ee9-9354-41ae-90ce-6401b6bf4a21',
  // Criteria weights:
  idPlanWeight: 'b3847fd3-9f5b-4dc4-a32b-5d5dee182e18',
  idCompetenceWeight: '7c86b3f5-2c2e-4294-8a98-9c6010158b61',
  idSolutionWeight: 'a108a89c-9557-4f93-9ab9-c6efb80cf9a8',
  idCooperationWeight: '03da9367-4967-4a69-a031-5ebb3973b6f7',
  // Hours change of key employees and consultants:
  idChangeHoursKeyEmployees: '317475ed-6041-4c5d-bd42-75117090b809',
  idChangeHoursConsultants: '9d92185b-e4b9-4fe8-981b-c7cad7115c2a',

};
export default deliveryAgreementIds;
