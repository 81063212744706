<template>
  <div>
    <h2 class="mt-0">Beregningsmetode</h2>
    <br />
    <p>
      Her kan du se de poster den evalueringstekniske pris består af.
    </p>
    <p>
      <strong>
        Kategori 1, Timekøb:
      </strong>
      <br />Prisen summeres over konsulentprofilerne.<br />
    </p>
    <p>
      Hvis der er valgt erfaringstillæg for profilen skal timeprisen reguleres for de timer som ligger over grænsen.<br />
      Antallet af timer med erfaringstillæg beregnes som:
      n<sub>tillæg</sub> =
      <CalculationTag
        :isHovered="hoveredTag === 'A'"
        isInteractive
        @highlightTag="highlightTag('A')"
        @unhighlightTag="unhighlightTag"
      >A</CalculationTag>-
      <CalculationTag
        :isHovered="hoveredTag === 'C'"
        isInteractive
        @highlightTag="highlightTag('C')"
        @unhighlightTag="unhighlightTag"
      >C</CalculationTag><br />
      Hvis n<sub>tillæg</sub> &lt; 0 sættes n<sub>tillæg</sub> = 0
      <br />
      <br />
      Hvis der ikke er valgt erfaringstillæg for profilen sættes n<sub>tillæg</sub> = 0
      <br />
      <br />
      pris = <span style="font-size: 200%;">&sum;</span>
      (<CalculationTag
        :isHovered="hoveredTag === 'A'"
        isInteractive
        @highlightTag="highlightTag('A')"
        @unhighlightTag="unhighlightTag"
      >A</CalculationTag>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'B'"
        isInteractive
        @highlightTag="highlightTag('B')"
        @unhighlightTag="unhighlightTag"
      >B</CalculationTag>&plus;
      n<sub>tillæg</sub>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'B'"
        isInteractive
        @highlightTag="highlightTag('B')"
        @unhighlightTag="unhighlightTag"
      >B</CalculationTag>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'D'"
        isInteractive
        @highlightTag="highlightTag('D')"
        @unhighlightTag="unhighlightTag"
      >D</CalculationTag>)
    </p>
    <p>
      <strong>
        Kategori 1, Løbende ydelser:
      </strong>
      <br />Prisen beregnes på tværs af alle konsulentprofiler.<br />
    </p>
    <p>
      pris = <CalculationTag
        :isHovered="hoveredTag === 'E'"
        isInteractive
        @highlightTag="highlightTag('E')"
        @unhighlightTag="unhighlightTag"
      >E</CalculationTag>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'F'"
        isInteractive
        @highlightTag="highlightTag('F')"
        @unhighlightTag="unhighlightTag"
      >F</CalculationTag>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'G'"
        isInteractive
        @highlightTag="highlightTag('G')"
        @unhighlightTag="unhighlightTag"
      >G</CalculationTag>&plus;
      <CalculationTag
        :isHovered="hoveredTag === 'F'"
        isInteractive
        @highlightTag="highlightTag('F')"
        @unhighlightTag="unhighlightTag"
      >F</CalculationTag>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'H'"
        isInteractive
        @highlightTag="highlightTag('H')"
        @unhighlightTag="unhighlightTag"
      >H</CalculationTag>
    </p>
    <p>
      <strong>
        Kategori 2, Timekøb:
      </strong>
      <br />Prisen summeres over konsulenter.<br />
    </p>
    <p>
      pris = <span style="font-size: 200%;">&sum;</span>
      <CalculationTag
        :isHovered="hoveredTag === 'I'"
        isInteractive
        @highlightTag="highlightTag('I')"
        @unhighlightTag="unhighlightTag"
      >I</CalculationTag>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'J'"
        isInteractive
        @highlightTag="highlightTag('J')"
        @unhighlightTag="unhighlightTag"
      >J</CalculationTag>
    </p>
    <p>
      <strong>
        Kategori 2, Løbende ydelser:
      </strong>
      <br />Prisen beregnes på tværs af alle konsulenter.<br />
    </p>
    <p>
      pris = <CalculationTag
        :isHovered="hoveredTag === 'K'"
        isInteractive
        @highlightTag="highlightTag('K')"
        @unhighlightTag="unhighlightTag"
      >K</CalculationTag>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'L'"
        isInteractive
        @highlightTag="highlightTag('L')"
        @unhighlightTag="unhighlightTag"
      >L</CalculationTag>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'M'"
        isInteractive
        @highlightTag="highlightTag('M')"
        @unhighlightTag="unhighlightTag"
      >M</CalculationTag>&plus;
      <CalculationTag
        :isHovered="hoveredTag === 'L'"
        isInteractive
        @highlightTag="highlightTag('L')"
        @unhighlightTag="unhighlightTag"
      >L</CalculationTag>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'N'"
        isInteractive
        @highlightTag="highlightTag('N')"
        @unhighlightTag="unhighlightTag"
      >N</CalculationTag>
    </p>
    <p>
      <strong>
        Kategori 2, Fastprisaftale:
      </strong>
      <br />Prisen summeres over konsulenter.<br />
    </p>
    <p>
      pris = <span style="font-size: 200%;">&sum;</span>
      <CalculationTag
        :isHovered="hoveredTag === 'O'"
        isInteractive
        @highlightTag="highlightTag('O')"
        @unhighlightTag="unhighlightTag"
      >O</CalculationTag>&times;
      <CalculationTag
        :isHovered="hoveredTag === 'P'"
        isInteractive
        @highlightTag="highlightTag('P')"
        @unhighlightTag="unhighlightTag"
      >P</CalculationTag>
    </p>
  </div>
</template>

<script setup lang="ts">
import { CalculationTag } from '@dims/components';
import { ref } from 'vue';

const emit = defineEmits<{ updateHoveredTag: [string] }>();
const hoveredTag = ref<string>();

function highlightTag(tag: string) {
  hoveredTag.value = tag;
  emit('updateHoveredTag', tag);
}
function unhighlightTag() {
  hoveredTag.value = '';
  emit('updateHoveredTag', '');
}
</script>
