import { useStore } from '@dims/components';
import { defineStore } from 'pinia';
import { DraftTender0214 } from '@/models';

export const useStore0214 = defineStore('0214', {
  getters: {
    draftTender() {
      const store = useStore();
      return store.draftTender as DraftTender0214 | null;
    },
  },
  actions: {
    setDraftTender(tender: DraftTender0214) {
      const store = useStore();
      store.setDraftTender(tender);
    },
  },
});

export default useStore0214;
