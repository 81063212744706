import { TemplateQuestionPanelState, Tab, TabType, DeepQuestionnaire } from '@dims/components';

const customerSpecificationTabs: Tab[] = [
  {
    title: 'Opgavebeskrivelse',
    section: 0,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Kategori 1',
    section: 1,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Kategori 2',
    section: 2,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Option(er)',
    section: 3,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Øvrige bilag til leveringskontrakten',
    section: 4,
    panelState: TemplateQuestionPanelState.INIT,
  },
];

type TabNumbers = [number, number, number];

function supplierOfferTabs(p: [number, number, number]) {
  return [
    {
      title: 'Kategori 1',
      section: p[0],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Kategori 2',
      section: p[1],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Option(er)',
      section: p[2],
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
}

// TODO: Change to keys
// const taskDescriptionSectionKey = '0214TaskDescriptionSection';
const deliverySectionKey = '0214DeliverySection';
const competenceSectionKey = '0214CompetenceSection';
const optionsSectionKey = '0214OptionSection';

let supplierOfferTabNumbers = new Array(3) as TabNumbers;

/** The index of the question with the given key */
function sectionIndex(offerQuestionnaire: DeepQuestionnaire | null, key: string) {
  if (offerQuestionnaire) {
    return offerQuestionnaire.questions.findIndex((q) => q.key === key);
  }
  return -1;
}

export function setSupplierPriceTabs(offerQuestionnaire: DeepQuestionnaire|null):number[] {
  supplierOfferTabNumbers = [
    sectionIndex(offerQuestionnaire, competenceSectionKey),
    sectionIndex(offerQuestionnaire, deliverySectionKey),
    sectionIndex(offerQuestionnaire, optionsSectionKey),
  ];
  return supplierOfferTabNumbers;
}

const questionnaireTabConfig = {
  getTabs: (type: TabType): Tab[] => {
    switch (type) {
      case 'CustomerSpecification':
        return customerSpecificationTabs;
      case 'SupplierOffer':
        return supplierOfferTabs(supplierOfferTabNumbers);
      default:
        return [];
    }
  },
};

export default questionnaireTabConfig;
