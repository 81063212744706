// This file contains configuration of the "Opsummering" view for the supplier.
// It lists question id's of questions on the second level in the questionnaire template
// to be excluded from the "Opsummering" view. Questions not in this list will be
// shown in the "Opsummering" view.

const excludedQuestions = [
  // Kategori 1
  '6eaac709-5b04-4236-a02d-9b2010ab8e27', // Vejledning (Kategori 1)
  '6d2c838f-dbb9-44fa-a0f6-e7c6807eab31', // Vejledning (Kategori 2)
  '703f9ba8-72ad-4226-9b99-3e3532a76c48', // CV Skabelon

  // Kategori 2
  'c65ee7fd-0452-4892-a517-dc146b7530f7', // Vejledning (Kategori 1)
  'f3a98e7f-938a-4dc3-8e7d-f3ef41e0ea72', // Vejledning (Kategori 2)
  'db05b77d-91ff-4346-9b6f-98e2210430f4', // CV Skabelon

  // Option(er)
  '6388437d-3dbb-4df4-be4b-9a11dc71a3d5', // Option(er)
  '91b2abed-ff33-44d2-bc29-264af9b49294', // Vejledning
];

export default excludedQuestions;
