<template>
  <v-radio-group v-model="selectedSolutionType">
    <template #label>
      <h3>Kategori</h3>
      <DimsTooltip>
        <p>Du kan købe konsulenttimer inden for 14 ydelsesområder, på aftalens to kategorier:</p>
        <p>I <strong>Kategori 1 Kompetencekøb</strong> fastlægger du krav til specifikke kompetencer og antal timer,
          samt løbende ydelser og leverandøren byder ind med motiverede CV’er og timepriser.</p>
        <p>I <strong>Kategori 2 Opgavekøb</strong> beskriver du de opgaver eller løsninger, du ønsker løst,
          og leverandørerne byder ind med omfang og karakter af deres indsats samt pris.</p>
        <p>Du kan altid ændre dit valg af kategori senere.</p>
      </DimsTooltip>
    </template>
    <v-radio
      class="ml-6"
      color="accent"
      :value="'Category1'"
    >
      <template #label>
        {{ solutionTypeLabel('Category1') }}
        <DimsTooltip>
          <p><strong>Kategori 1 - Kompetencekøb</strong></p>
          <p>Vælg kompetencekøb, når du har et behov for ydelser fra it-konsulenter med særlige kompetencer i et omfang, du selv definerer.</p>
          <p>I kategori 1 byder leverandøren ind med motiverede CV'er og timepriser.</p>
        </DimsTooltip>
      </template>
    </v-radio>
    <v-radio
      class="ml-6"
      color="accent"
      :value="'Category2'"
    >
      <template #label>
        {{ solutionTypeLabel('Category2') }}
        <DimsTooltip>
          <p><strong>Kategori 2 - Opgavekøb</strong></p>
          <p>Vælg opgavekøb, når du har et behov for it-konsulentydelser til løsning af en konkret opgave.</p>
          <p>I kategori 2 byder leverandøren ind med hvordan opgaven løses: tids-, aktivitets- og ressourceplan evt.
            kombineret med kompetencer (motiverede CV'er) og/eller ydelsesspecifik løsningsbeskrivelse og/eller samarbejde og metode, samt priser.</p>
        </DimsTooltip>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import useStore0214 from '@/store/store0214';
import { SolutionType } from '@/models';
import { solutionTypeLabel } from '@/services/tenderDisplay';

const store = useStore0214();

const selectedSolutionType = computed({
  get(): SolutionType | undefined {
    let solutionType;
    if (store.draftTender) {
      solutionType = store.draftTender.data.solutionType;
    }
    return solutionType;
  },
  set(solutionType: SolutionType | undefined) {
    if (store.draftTender && solutionType) {
      store.setDraftTender({
        ...store.draftTender,
        data: {
          ...store.draftTender.data,
          solutionType },
      });
    }
  },
});

</script>

<style scoped>
h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
